.centered-container {
	display: flex;
	flex-direction: column;
	margin-left: auto;
	margin-right: auto;
	margin-top: auto;
	margin-bottom: auto;
}

.store-button {
	box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
		0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
	border-radius: 0.5em;
	border-color: rgb(63, 66, 84);
	border-width: 0px 0px 2px 0px;
	display: flex;
	width: 170px;
	justify-content: space-around;
	align-items: center;
}

.android-button {
	margin-right: 10px;
}
.ios-button {
	margin-left: 10px;
}

.store-container {
	display: flex;
	justify-content: center;
}

@media only screen and (max-width: 720px) {
	.store-container {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.android-button {
		margin-right: 0px;
	}
	.ios-button {
		margin-left: 0px;
		margin-top: 20px;
	}
}
